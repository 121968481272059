import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BookingForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/GlobalCoverage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/ReadMoreReviewsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/WhatWeDoSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/YourTravelPartnerSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/small-gradient-bg.css");
